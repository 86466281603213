import React, {Component} from 'react'
import { Switch, Route, withRouter } from "react-router-dom";
import Song from './Song'
import "../styles/Game.scss"
import Sidebar from './Sidebar'
import Search from './Search'
import Playlist from './Playlist'
import Artist from './Artist'
import Home from './Home'


class Game extends Component {
    constructor(props) {
        super(props)
        this.state = {
            playlist: {name: "My Playlist", contents: []},
            settings: {
                playOnLoad: false,
                seed: '',
            },
        }
        this.addSong = this.addSong.bind(this)
        this.removeSong = this.removeSong.bind(this)
        this.updatePlaylist = this.updatePlaylist.bind(this)
        this.songInPlaylist = this.songInPlaylist.bind(this)
        this.getArtist = this.getArtist.bind(this)
    }

    componentDidMount() {
        this.checkCookie();
    }

    setCookie(name, value, exdays=0) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = (exdays===0) ? "" : "expires="+d.toUTCString() + ";path=/";
        document.cookie = name + "=" + JSON.stringify(value) + ";" + expires;
    }

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    checkCookie() {
        let playlist_str = this.getCookie("playlist");
        if (playlist_str !== "") {
            let playlist = JSON.parse(playlist_str);
            if(!playlist.contents) this.updatePlaylist(playlist.name, []);
            else this.updatePlaylist(playlist.name, playlist.contents);
        }
        let settings_str = this.getCookie("settings");
        if (settings_str !== "") {
            let settings = JSON.parse(settings_str);
            this.updateSettings(settings);
        }
        // let token = this.getCookie("token");
        // if (!token) this.authorizeSpotify();
    }

    authorizeSpotify(callback) {
        var request = new XMLHttpRequest();
        request.open('POST', 'https://accounts.spotify.com/api/token');
        request.setRequestHeader('Authorization', 'Basic eb9ff487a6e442df8a342e0f0710909f:b505d00d61654a32b0941c467cb6873d')
        let contentType = "application/json;charset=utf-8;";
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                // var type = request.getResponseHeader('Content-Type');
                console.log(request.response())
                // if (type.indexOf("text") !== 1) {
                //     console.log(JSON.parse(request.responseText))
                //     callback(JSON.parse(request.responseText))
                // }
            }
        }
        request.send(JSON.stringify({grant_type: 'client_credentials'}));
    }

    getArtist(artist) {
        this.setState({ artist: artist}, ()=> console.log(this.state.artist))
    }

    songInPlaylist(song) {
        return this.state.playlist.contents.map((s)=>JSON.stringify(s)).includes(JSON.stringify(song))
    }

    updatePlaylist(name, contents) {
        this.setState({playlist: {name: name, contents: contents}}, () => this.setCookie("playlist", this.state.playlist))
    }

    addSong(song) {
        if(this.songInPlaylist(song)) return;
        var contents = this.state.playlist.contents;
        contents.push(song);
        this.updatePlaylist(this.state.playlist.name, contents);
    }

    removeSong(index) {
        var contents = this.state.playlist.contents;
        contents.splice(index, 1);
        this.updatePlaylist(this.state.playlist.name, contents);
    }

    updateSettings = (newSettings) => {
        this.setState({settings: newSettings}, () => this.setCookie("settings", this.state.settings))
    }

    render() {
        return (
            <div id="game">
                <Sidebar title={<div id="apptitle" onClick={()=>{window.location.href="/#/"; window.location.reload()}} title="a lyric guessing game">Versed</div>}>
                    <Search addSong={this.addSong} songInPlaylist={this.songInPlaylist} getArtist={this.getArtist}/>
                    <Playlist 
                        playlist={this.state.playlist} 
                        removeSong={this.removeSong} 
                        updatePlaylist={this.updatePlaylist} 
                        location={this.props.location} 
                        getArtist={this.getArtist}
                        seed={this.state.settings.seed}
                    />
                </Sidebar>
                {this.state.artist!==undefined && <Artist artist={this.state.artist} getArtist={this.getArtist} addSong={this.addSong} songInPlaylist={this.songInPlaylist} />}
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(props) => <Home {...props} settings={this.state.settings} updateSettings={this.updateSettings}/>}
                    />
                    <Route
                        path="/songs/:genius_id"
                        render={(props) => <Song {...props} playOnLoad={this.state.settings.playOnLoad}/>}
                    />
                </Switch> 
            </div>
        );
    
    }
} export default withRouter(Game);